import React, { FC } from "react";

interface Props {
  title: string;
  className?: string;
}

const Badge: FC<Props> = ({ title, className }) => {
  return (
    <div
      className={`w-fit rounded-md bg-white/20 px-4 py-2 text-sm text-white md:text-base ${className}`}
    >
      {title}
    </div>
  )
};

export default Badge;
