import * as React from "react";
import { Fade } from 'react-reveal'
import { Helmet } from 'react-helmet'
import Card from '../components/Card'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

import airbus from '../assets/images/projects/airbus.webp'
import nebuleuse from '../assets/images/projects/design-system__nebuleuse.webp'
import onpiste from '../assets/images/projects/onpiste__rossignol.webp'
import sophieCara from '../assets/images/projects/sophie-cara.webp'
import vrtice from '../assets/images/projects/application-saas__vrtice.webp'
import walletx from '../assets/images/projects/design-concept__wallet-x.webp'

const PlayPage = () => {
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    setIsLoading(false)
  }, [])

  return (
    <main className="bg-[url('../assets/images/background-3.webp')] bg-cover bg-no-repeat">
      <Helmet htmlAttributes={{ lang: 'fr-FR' }}>
        <title>
          Play | Ksatra SEM - Fullstack designer freelance à Toulouse
        </title>
        <meta name="description" content="Découvrez quelques projets" />
      </Helmet>

      {!isLoading && <Navbar bgColor="bg-black" textColor="text-white" />}

      <div className="mx-6 pt-8 text-white lg:px-32 lg:pt-24">
        <Fade delay={800}>
          <h3 className="pb-6 text-lg">Play.</h3>
        </Fade>
        <Fade bottom>
          <h1 className="mb-16 font-serif text-4xl leading-tight lg:text-6xl">
            Découvrez quelques projets
          </h1>
        </Fade>

        <Fade bottom>
          <div className="grid gap-16 md:grid-cols-2 lg:gap-12 lg:px-0 2xl:grid-cols-3">
            <Card
              description="Conception et développement de la solution data product management"
              expertises={['UX/UI Design', 'React']}
              media={airbus}
              mediaAlt="Aperçu projet Airbus"
              privateProject
              title="Airbus"
            />
            <Card
              description="Construction d'un Design System sous Figma"
              expertises={['Design System', 'DesignOps']}
              link="https://dribbble.com/shots/19417125-N-buleuse-Design-System"
              media={nebuleuse}
              mediaAlt="Aperçu Design System de Nébuleuse"
              title="Nébuleuse Design System"
            />
            <Card
              description="Concept design"
              expertises={['UI Design']}
              link="https://dribbble.com/shots/19417224-WalletX-Crypto-tracking"
              media={walletx}
              mediaAlt="Aperçu walletx portefeuille crypto"
              title="WalletX"
            />
            <Card
              description="Conception UX/UI de l'application web et mobile On Piste"
              expertises={['UX/UI Design', 'UI Kit']}
              link="https://dribbble.com/shots/17826342-On-Piste-Webapp"
              media={onpiste}
              mediaAlt="Aperçu application web On Piste"
              subtitle="(@lachouetteco)"
              title="Rossignol"
            />

            <Card
              description="Conception UX/UI de l'application web"
              expertises={['UX/UI Design', 'Design Sprint']}
              link="https://vrtice.com/"
              media={vrtice}
              mediaAlt="Aperçu application web de Vrtice"
              subtitle="(@lachouetteco)"
              title="Vrtice"
            />

            <Card
              description="Diététicienne nutrinionniste"
              expertises={['UI Design', 'Webflow']}
              link="https://www.instagram.com/p/Cf62xV2DAT1/"
              media={sophieCara}
              mediaAlt="Aperçu site vitrine de Sophie Cara"
              title="Sophie Cara"
            />
          </div>
        </Fade>
      </div>

      <Footer />
    </main>
  )
}

export default PlayPage;
