import React from 'react'

import Badge from './Badge'
import externalLinkIcon from '../assets/icons/export.svg'
import lockIcon from '../assets/icons/lock.svg'

interface ICardProps {
  className?: string
  description: string
  expertises: string[]
  link?: string
  media: string
  mediaAlt: string
  privateProject?: boolean
  subtitle?: string
  title: string
}

const Card = ({
  className,
  description,
  expertises,
  link,
  media,
  mediaAlt,
  privateProject,
  subtitle,
  title,
}: ICardProps) => {
  return (
    <div className={`overflow-hidden ${className}`}>
      {link ? (
        <a href={link} target="_blank">
          <img
            src={media}
            alt={mediaAlt}
            className="mb-8 rounded-lg object-fill"
          ></img>
        </a>
      ) : (
        <img
          src={media}
          alt={mediaAlt}
          className="mb-8 rounded-lg object-fill"
        ></img>
      )}

      <div className="flex flex-col gap-4">
        {link ? (
          <div className="flex flex-col items-baseline gap-1">
            <a href={link} target="_blank">
              <p className="flex flex-row gap-4 font-serif text-3xl">
                {title}{' '}
                <img
                  src={privateProject ? lockIcon : externalLinkIcon}
                  className="w-4"
                  alt="flèche vers la droite"
                ></img>
              </p>
            </a>
            {subtitle ? (
              <p className="text-lg text-gray-400">
                <a
                  href="https://www.lachouette.co/"
                  target="_blank"
                  className="font-semibold"
                >
                  {subtitle}
                </a>
              </p>
            ) : (
              ''
            )}
          </div>
        ) : (
          <div className="flex flex-col items-baseline gap-1">
            <p className="flex flex-row gap-4 font-serif text-3xl">
              {title}{' '}
              <img
                src={privateProject ? lockIcon : externalLinkIcon}
                className="w-4"
                alt="flèche vers la droite"
              ></img>
            </p>
            {subtitle ? (
              <p className="text-lg text-gray-400">
                {' '}
                <a
                  href="https://www.lachouette.co/"
                  target="_blank"
                  className="font-semibold"
                >
                  {subtitle}
                </a>
              </p>
            ) : (
              ''
            )}
          </div>
        )}

        <p className="text-lg">{description}</p>
        <div className="flex flex-row gap-4">
          {expertises.map((value, key) => (
            <Badge title={value} key={key} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Card
